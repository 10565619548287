// 验证手机号
export function mobile (value) {
  return /^1[3,4,5,6,7,8,9]\d{9}$/.test(value)
}

// 验证金额
export function amount (value) {
  return /((^[1-9]\d*)|^0)(\.\d{0,3}){0,1}$/.test(value)
}

// 验证密码
export function RegPassword (value) {
  return /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{8,16}$/.test(value)
}

// 模糊查询
export function Like (list, title, name) {
  const arr = []
  list.map(item => {
    if (name) {
      if (item[name].indexOf(title) > -1) {
        arr.push(item)
      }
    } else {
      if (item.indexOf(title) > -1) {
        arr.push(item)
      }
    }
  })
  return arr
}

// 获取几月前日期
export function getMonth (month) {
  const time = new Date()
  if (month) {
    time.setMonth(time.getMonth() + month)
  }
  const Y = time.getFullYear()
  const M = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
  const D = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
  const com = Y + '-' + M + '-' + D
  return com
}

// 加日期
export function getDate (date, day) {
  const time = new Date(date)
  if (day) {
    time.setDate(time.getDate() + day)
  }
  const Y = time.getFullYear()
  const M = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
  const D = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
  const com = Y + '-' + M + '-' + D
  return com
}

export function getTime (hours) {
  const time = new Date()

  if (hours) {
    time.setHours(time.getHours() + hours)
  }

  const Y = time.getFullYear()
  const M = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
  const D = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
  const h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
  const m = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
  const s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
  const com = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
  return com
}

export function setBuynum (item) {
  const buynum = Number(item.buynum)
  const stepnum = Number(item.stepnum)
  const sl = Number(item.sl)
  if (!buynum) return stepnum
  if (buynum > sl) {
    const step = Math.floor(sl / stepnum)
    return step * stepnum
  }
  const num = Math.floor(buynum / stepnum)
  const round = Math.round((buynum % stepnum) / stepnum)
  let totalNum = num * stepnum

  if (totalNum < stepnum) {
    totalNum = stepnum
  } else if (round == 1) {
    totalNum += stepnum
  }

  return totalNum
}
