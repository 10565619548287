import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import $store from '@/store/index'
import { RoleList } from '@/util/config'
import $http from '@/network/http'

Vue.use(VueRouter)

/**
 * @type {RouteConfig}
 */
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../Layout/Layout.vue'),
    children: [
      {
        path: '/search',
        name: 'search',
        component: () => import('../views/search.vue'),
        meta: {
          title: '药品搜索',
          keepAlive: true
        }
      },
      {
        path: '/cart',
        name: 'cart',
        component: () => import('../views/cart.vue'),
        meta: {
          title: '购物车'
        }
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/notice.vue'),
        meta: {
          title: '公告信息'
        }
      },
      {
        path: '/flagship',
        name: 'flagship',
        component: () => import('../views/flagship.vue'),
        meta: {
          title: '旗舰店'
        }
      },
      {
        path: '/hotSell',
        name: 'hotSell',
        component: () => import('../views/hotSell.vue'),
        meta: {
          title: '热销推荐',
          keepAlive: true
        }
      },
      {
        path: '/exGratia',
        name: 'exGratia',
        component: () => import('../views/exGratia.vue'),
        meta: {
          title: '特惠专区',
          keepAlive: true
        }
      },
      {
        path: '/devaluation',
        name: 'Devaluation',
        component: () => import('../views/Devaluation.vue'),
        meta: {
          title: '首推单品包邮'
        }
      },
      {
        path: '/Seckill',
        name: 'Seckill',
        component: () => import('../views/Seckill.vue'),
        meta: {
          title: '限时秒杀',
          auth: true
        }
      },
      {
        path: '/pick',
        name: 'Pick',
        component: () => import('../views/pick.vue')
      },
      {
        path: '/special',
        name: 'special',
        component: () => import('../views/special.vue')
      },
      {
        path: '/group',
        name: 'group',
        component: () => import('../views/group.vue')
      },
      {
        path: '/discount',
        name: 'discount',
        component: () => import('../views/discount.vue')
      },
      {
        path: '/specialized',
        name: 'specialized',
        component: () => import('../views/specialized.vue')
      },
      {
        path: '/recomment',
        name: 'recomment',
        component: () => import('../views/recomment.vue'),
        meta: {
          title: '每周推荐',
          auth: true,
          keepAlive: true
        }
      },
      {
        path: '/nealy',
        name: 'nealy',
        component: () => import('../views/nealy.vue'),
        meta: {
          title: '近效期药品',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/shopDetail',
    name: 'shopDetail',
    component: () => import('../views/shopDetail.vue'),
    meta: {
      title: '药品详情'
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/activity.vue'),
    meta: {
      title: '药品详情'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/user',
    name: 'user',
    redirect: '/404',
    component: () => import('../Layout/user/main.vue'),
    children: [
      {
        path: '/user/certification',
        name: 'certification',
        component: () => import('../views/user/certification.vue'),
        meta: {
          title: '资质认证'
        }
      },
      {
        path: '/user/personal',
        name: 'personal',
        component: () => import('../views/user/personal.vue'),
        meta: {
          title: '完善资料'
        }
      },
      {
        path: '/user/order',
        name: 'order',
        component: () => import('../views/user/order.vue'),
        meta: {
          title: '我的订单',
          keepAlive: true
        }
      },
      {
        path: '/user/userCenter',
        name: 'userCenter',
        component: () => import('../views/user/userCenter.vue'),
        meta: {
          title: '我的信息'
        }
      },
      {
        path: '/user/collectDrug',
        name: 'collectDrug',
        component: () => import('../views/user/collectDrug.vue'),
        meta: {
          title: '关注的商品'
        }
      },
      {
        path: '/user/collectStore',
        name: 'collectStore',
        component: () => import('../views/user/collectStore.vue'),
        meta: {
          title: '关注的店铺'
        }
      },
      {
        path: '/user/oftenShop',
        name: 'oftenShop',
        component: () => import('../views/user/oftenShop.vue'),
        meta: {
          title: '常购商品'
        }
      },
      {
        path: '/user/history',
        name: 'history',
        component: () => import('../views/user/history.vue'),
        meta: {
          title: '浏览历史'
        }
      },
      {
        path: '/user/afterSale',
        name: 'afterSale',
        component: () => import('../views/user/afterSale.vue'),
        meta: {
          title: '退款售后'
        }
      },
      {
        path: '/user/invoice',
        name: 'invoice',
        component: () => import('../views/user/invoice.vue'),
        meta: {
          title: '发票信息'
        }
      }
    ]
  },
  {
    path: '/user/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/user/orderDetail.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/user/comment',
    name: 'comment',
    component: () => import('../views/user/comment.vue'),
    meta: {
      title: '待评论'
    }
  },
  {
    path: '/user/logistics',
    name: 'logistics',
    component: () => import('../views/user/logistics.vue'),
    meta: {
      title: '物流管理'
    }
  },
  {
    path: '/salesReturn',
    name: 'salesReturn',
    component: () => import('../views/salesReturn.vue'),
    meta: {
      title: '退货退款'
    }
  },
  {
    path: '/createOrder',
    name: 'createOrder',
    component: () => import('../views/createOrder.vue'),
    meta: {
      title: '创建订单'
    }
  },
  {
    path: '/terrace',
    name: 'terrace',
    component: () => import('../views/certificate/terrace.vue'),
    meta: {
      title: '平台资质'
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay.vue'),
    meta: {
      title: '支付'
    }
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/paySuccess.vue'),
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/WxPay',
    name: 'WxPay',
    component: () => import('../views/pays/wx.vue'),
    meta: {
      title: '微信支付'
    }
  },
  {
    path: '/ZfbPay',
    name: 'ZfbPay',
    component: () => import('../views/pays/zfb.vue'),
    meta: {
      title: '支付宝'
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('../views/password.vue'),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/certificate/agreement.vue'),
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/certificate/privacy'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/yyzz',
    name: 'yyzz',
    component: () => import('../views/certificate/yyzz.vue'),
    meta: {
      title: '营业执照'
    }
  },
  {
    path: '/zgzs',
    name: 'zgzs',
    component: () => import('../views/certificate/zgzs.vue'),
    meta: {
      title: '资格证书'
    }
  },
  {
    path: '/zzxkz',
    name: 'zzxkz',
    component: () => import('../views/certificate/zzxkz.vue'),
    meta: {
      title: '增值许可证'
    }
  },
  {
    path: '/OnlineFiling',
    name: 'OnlineFiling',
    component: () => import('../views/certificate/OnlineFiling.vue'),
    meta: {
      title: '备案信息表'
    }
  },
  {
    path: '/sfpt',
    name: 'sfpt',
    component: () => import('../views/certificate/sfpt.vue'),
    meta: {
      title: '备案凭证'
    }
  },
  {
    path: '/baxx',
    name: 'baxx',
    component: () => import('../views/certificate/baxx.vue'),
    meta: {
      title: '备案信息'
    }
  },
  {
    path: '/storeShop',
    name: 'storeShop',
    component: () => import('../views/Shops.vue'),
    meta: {
      title: '店铺专区',
      auth: true,
      keepAlive: true
    }
  },
  {
    path: '/qualification',
    name: 'qualification',
    component: () => import('../views/qualification.vue'),
    meta: {
      title: '商家资质',
      auth: true
    }
  },
  {
    path: '/productQualitySafety',
    name: 'productQualitySafety',
    component: () => import('../views/cpgg.vue'),
    meta: {
      title: '产品质量安全公告'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/ggDetail.vue'),
    meta: {
      title: '公告详情'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const roles = RoleList(true)
const notLog = RoleList()

router.beforeEach((to, from, next) => {
  if (window.localStorage.getItem('TOKENID')) {
    if (roles.indexOf(to.path) != -1 && !$store.state.roles) {
      alert('资质审核通过后可访问')
      window.location.replace('/user/certification')
      next()
    } else {
      next()
    }
  } else {
    if (to.query.tokenid) {
      $http
        .get('hyeyAndYyh/hyeyAccountLongin', {
          params: {
            messageId: to.query.tokenid
          },
          custom: {
            auth: false,
            toast: false,
            loading: false
          }
        })
        .then((res) => {
          if (res.data.code == 0) {
            $store.dispatch('SET_TOKEN', res.data.data.token)
            $store.dispatch('SET_USERNAME', res.data.data.username)
            $store.dispatch('SET_COMPANY', res.data.data.company)
            next()
          } else {
            alert('该账号没有开通源药惠权限，请联系管理员')
            window.location.replace('/login')
            next()
          }
        })
        .catch((err) => {
          alert('服务器故障，请联系管理员')
          window.location.replace('/login')
          next()
        })
    } else {
      if (notLog.indexOf(to.path) != -1 || to.path.indexOf('/user/') > -1) {
        window.location.replace('/login')
        next()
      } else {
        next()
      }
    }
  }
})

router.afterEach((to, from) => {
  const docEl = document.querySelector('#app')
  if (to.meta.keepAlive) {
    setTimeout(() => {
      docEl.scrollTop = $store.state.scrolTop
    }, 100)
  } else {
    window.scrollTo(0, 0)
    docEl.scrollTop = 0
  }
})

const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch((error) => error)
}

export default router
