import axios from 'axios'
import { Message, Notification, MessageBox } from 'element-ui'
import Vue from 'vue'

let timer = null

const http = axios.create({
  baseURL: 'https://www.yuanyaohui.com/webapi/',
  method: 'get',
  timeout: 35000,
  custom: {
    auth: true,
    toast: true,
    loading: true
  }
})

http.interceptors.request.use(function (config) {
  if (config.custom.auth) {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('TOKENID')}`
  }

  if (config.custom.loading && !timer) {
    timer = setTimeout(() => {
      Vue.prototype.$Mloading.show()
      timer = null
    }, 500)
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

http.interceptors.response.use(function (response) {
  getMsg(response.data, response.config.custom)
  if (response.config.custom.loading) {
    Vue.prototype.$Mloading.hide()
    clearTimeout(timer)
    timer = null
  }
  return response
}, (error) => {
  const custom = error.config.custom
  if (custom.loading) {
    Vue.prototype.$Mloading.hide()
    clearTimeout(timer)
    timer = null
  }

  if (custom.toast) {
    let message = ''
    if (error && error.response) {
      switch (error.response.status) {
        case 302: message = '接口重定向了！'; break
        case 400: message = '参数不正确！'; break
        case 401: message = '您未登录，或者登录已经超时，请先登录！'; break
        case 403: message = '您没有权限操作！'; break
        case 404: message = `请求地址出错: ${error.response.config.url}`; break // 在正确域名下
        case 408: message = '请求超时！'; break
        case 409: message = '系统已存在相同数据！'; break
        case 500: message = '服务器内部错误！'; break
        case 501: message = '服务未实现！'; break
        case 502: message = '网关错误！'; break
        case 503: message = '服务不可用！'; break
        case 504: message = '服务暂时无法访问，请稍后再试！'; break
        case 505: message = 'HTTP版本不受支持！'; break
        default: message = '异常问题，请联系管理员！'; break
      }

      if (error.message.includes('timeout')) message = '网络请求超时！'
      if (error.message.includes('Network')) message = window.navigator.onLine ? '服务端异常！' : '您断网了！'

      error.message && Notification.error({
        title: '错误',
        message,
        duration: 3000
      })
    }
  }

  return Promise.reject(error)
})

function getMsg (res, auth) {
  if (auth.toast && res.code != 0) {
    if (res.msg == '没有完善信息,请完善信息') {
      // alert('没有完善信息,请完善信息');
      MessageBox.alert('没有完善信息，是否去完善信息？', '提示', {
        confirmButtonText: '确定'
      }).then(res => {
        window.location.replace('/user/personal')
      }).catch(err => {
        window.location.replace('/')
      })
      //
    } else if (res.code == 4010001) {
      // Message.error('已掉线，请重新登录');
      // alert('已掉线，请重新登录')
      MessageBox.alert('已掉线，请重新登录', '提示', {
        confirmButtonText: '确定'
      }).then(res => {
        window.location.replace('/login')
      }).catch(err => {
        window.location.replace('/')
      })
    } else if (res.code == 4030006) {
      MessageBox.alert('你的权限不足，请联系管理员添加，如已添加请重新登录', '提示', {
        confirmButtonText: '确定'
      }).then(res => {
        window.location.replace('/login')
      }).catch(err => {

      })
    } else {
      res.msg && Message.error(res.msg)
    }
  }
}

export default {
  post (url, data, config = {}) {
    return http.post(url, data, { ...config }).then(res => {
      return res
    }).catch(err => {
      return Promise.reject(err)
    })
  },

  get (url, config = {}) {
    return http.get(url, { ...config }).then(res => {
      return res
    }).catch(err => {
      return Promise.reject(err)
    })
  },

  delete (url, config = {}) {
    return http.delete(url, { ...config }).then(res => {
      return res
    }).catch(err => {
      return Promise.reject(err)
    })
  },

  put (url, data, config = {}) {
    return http.put(url, data, { ...config }).then(res => {
      return res
    }).catch(err => {
      return Promise.reject(err)
    })
  }
}
