import Loading from './hock.vue'

let instance = null

export default {

  install (Vue) {
    if (!instance) {
      const MyLoadingComponent = Vue.extend(Loading)

      instance = new MyLoadingComponent({
        el: document.createElement('div')
      })

      document.body.appendChild(instance.$el)
    }

    instance.isShow = false

    const customMethods = {
      show () {
        instance.isShow = true
      },

      hide () {
        instance.isShow = false
      }
    }

    if (!Vue.$Mloading) {
      Vue.$Mloading = customMethods
      Vue.prototype.$Mloading = Vue.$Mloading
    } else {
      return new Error('此方法已存在')
    }
  }
}
