import $http from '@/network/http'
import { getTime } from '@/util/test'

export default {
  Logout (context) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await $http.delete('login/do-logout', {
          data: {
            token: window.localStorage.getItem('TOKENID')
          },
          custom: {
            loading: false,
            toast: false
          }
        })
        console.log(res)
        if (res.data.code == 0) {
          console.log(222)
          context.dispatch('SET_TOKEN', null)
          context.dispatch('SET_COMPANY', null)
          resolve(true)
        } else {
          resolve(false)
        }
      } catch (e) {
        resolve(true)
      }
    })
  },

  // 获取购物车数量
  async getCartNum (context) {
    try {
      const res = await $http.get('cart/products/sum', {
        custom: {
          toast: false,
          loading: false
        }
      })
      if (res.data.code == 0) {
        window.sessionStorage.setItem('CART_NUM', res.data.data)
        context.commit('SET_CART_NUM', res.data.data)
      } else {
        window.sessionStorage.removeItem('CART_NUM')
        context.commit('SET_CART_NUM', 0)
      }
    } catch (e) {
      window.sessionStorage.removeItem('CART_NUM')
      context.commit('SET_CART_NUM', 0)
    }
  },

  // 刷新token
  async flushToken (context) {
    try {
      const res = await $http.get('Information/flush_token', {
        custom: {
          toast: false,
          loading: false
        }
      })
      if (res.data.code == 0) {
        if (res.data.data) {
          const date = getTime(12)
          window.localStorage.setItem('LAST_DATE', date)
          context.commit('SET_DATE', date)
          context.dispatch('SET_ROLES', 'member')
          context.dispatch('SET_TOKEN', res.data.data)
        }
      }
    } catch (e) {

    }
  },

  async getAddress () {
    try {
      const res = await $http.get('cart/asddress', {
        custom: {
          toast: false,
          loading: false
        }
      })
      if (res.data.code == 0) {
        console.log(res.data.data)
        // window.sessionStorage.setItem('CART_NUM', res.data.data)
        // context.commit('SET_CART_NUM', res.data.data)
      }
    } catch (e) {

    }
  },

  // 修改tokenid
  SET_TOKEN (context, token) {
    token ? window.localStorage.setItem('TOKENID', token) : window.localStorage.removeItem('TOKENID')
    context.commit('SET_TOKEN1', token)
  },

  SET_DATE (context, date) {
    date ? window.localStorage.setItem('LAST_DATE', date) : window.localStorage.removeItem('LAST_DATE')
    context.commit('SET_DATE1', date)
  },

  SET_USERNAME (context, username) {
    window.localStorage.setItem('USERNAME', username)
    context.commit('SET_USERNAME1', username)
  },
  SET_PASSWORD (context, password) {
    window.sessionStorage.setItem('PASSWORD', password)
    context.commit('SET_PASSWORD1', password)
  },

  SET_USERID (context, userid) {
    userid ? window.sessionStorage.setItem('USERID', userid) : window.sessionStorage.removeItem('USERID')
    context.commit('SET_USERID1', userid)
  },

  SET_SORT_TYPE (context, type) {
    window.localStorage.setItem('SORTTYPE', type)
    context.commit('SET_SORT_TYPE1', type)
  },

  SET_SCROLLTOP (context, num) {
    context.commit('SET_SCROLLTOP1', num)
  },

  SET_ROLES (context, role) {
    role ? window.localStorage.setItem('ROLES', role) : window.localStorage.removeItem('ROLES')
    context.commit('SET_ROLES1', role)
  },

  SET_COMPANY (context, company) {
    company ? window.localStorage.setItem('COMPANY', company) : window.localStorage.removeItem('COMPANY')
    context.commit('SET_COMPANY1', company)
  },

  SET_CERTIFICATE (context, certificate) {
    certificate ? window.sessionStorage.setItem('CERTIFICATE', certificate) : window.sessionStorage.removeItem('CERTIFICATE')
    context.commit('SET_CERTIFICATE1', certificate)
  },

  SET_ROLE_COMPANY (context, company) {
    company ? window.sessionStorage.setItem('ROLECOMPANY', company) : window.sessionStorage.removeItem('ROLECOMPANY')
    context.commit('SET_ROLE_COMPANY1', company)
  },

  SET_SEARCH_NAME (context, name) {
    name ? window.sessionStorage.setItem('SEARCH_NAME', name) : window.sessionStorage.removeItem('SEARCH_NAME')
    context.commit('SET_SEARCH_NAME1', name)
  },

  SET_SEARCH_NAME2 (context, name) {
    name ? window.localStorage.setItem('SEARCH_NAME1', name) : window.localStorage.removeItem('SEARCH_NAME1')
    context.commit('SET_SEARCH_NAME3', name)
  },

  SET_QUERY (context, obj) {
    obj ? window.sessionStorage.setItem('QUERY_OBJ', JSON.stringify(obj)) : window.sessionStorage.removeItem('QUERY_OBJ')
    context.commit('SET_QUERY1', obj)
  }
}
