import axios from 'axios'

export default function GetImage (id, index) {
  return new Promise((resolve, reject) => {
    axios
      .get('https://hyapi.hyey.cn/DsApi/GetImage', {
        params: {
          token: '0f4ea644-58fd-4a25-8857-956c3492aa99',
          ypbh: id
        }
      })
      .then((res) => {
        resolve({
          url: res.data,
          index
        })
      })
  })
}
