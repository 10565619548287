<template>
  <div class="loading" v-if="isShow">
    <img src="../../assets/img/loding.gif" alt="" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: false
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  // position: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // z-index: 999;
  // background-color: rgba(0, 0, 0, 0.8);
  // display: flex;
  // width: 100%;
  // height: 100%;

  img {
    // width: 150px;
    // height: 150px;
    // margin: auto;
    // z-index: 1000;
    position: fixed;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }
}
</style>
